


<template>

    <v-row>
        <v-col
                cols="12"
                sm="6"
        >
            <v-card
                    class="pa-2"
                    elevation="0"
            >
                <h2>{{header}}</h2>
                <v-alert type="error"
                         close-text="Закрыть сообщение"
                         dismissible
                         v-model="alertError.show"
                         @click="navBack()"
                >
                    {{alertError.text}}
                </v-alert>
                <v-alert type="warning"
                         close-text="Закрыть сообщение"
                         dismissible
                         v-model="alertWarning.show"
                         @click="navBack()"
                >
                    {{alertWarning.text}}
                </v-alert>
                <v-btn v-if="navBackShow" block
                       color="primary"
                       @click.prevent="navBack()"
                       elevation="10"
                >
                    Обратно
                </v-btn>
                <v-card
                        class="mt-6 pa-2"
                        elevation="10"
                        outlined
                        :loading="loading"
                >


                    <v-list-item-group
                            shaped
                            color="primary"
                            v-model="selectedItem"
                    >
                        <v-list-item
                                v-for="(e,i) in arr"
                                :key="i"
                                @click="onNext(i)"
                                :class="{disabled: e.FREE=='0'}"
                                :disabled="e.FREE=='0'"
                        >
                            <v-list-item-icon>
                                <v-icon v-if="e.FREE=='0'"
                                        color="gray"
                                >
                                    {{icon.no}}
                                </v-icon>
                                <v-icon v-else
                                        color="green"
                                >
                                    {{icon.yes}}
                                </v-icon>

                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="e.NNAME"></v-list-item-title>
                                <v-list-item-subtitle v-text="e.REM"></v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-card>
            </v-card>
        </v-col>
        <v-col
                cols="12"
                sm="6"
        >
            <v-card
                    class="pa-2"
                    elevation="0"
            >
                <h2>Выбрано</h2>
                <v-card
                        class="mt-6 pa-2"
                        elevation="0"
                        outlined
                >


                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-html="LPU.name"/>
                            <v-list-item-subtitle v-text="LPU.address"/>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-text="FIN.name"/>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-text="AGE.name"/>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-text="MEDG.PANELCAPTION1"/>
                            <v-list-item-subtitle v-text="MEDG.PANELCAPTION2"/>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-text="MED.NNAME"/>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-text="DDATE.DDATE"/>
                        </v-list-item-content>
                    </v-list-item>


                </v-card>
            </v-card>
        </v-col>

    </v-row>



</template>
<style scoped>

    .disabled {
        pointer-events: none;
        text-decoration: line-through;
        color: grey;
    }
</style>

<script>
    import axios from 'axios'
    import JSON_to_URLEncoded from '../tools/json2url'
    import post, {postLog} from '../tools/post'
    import {toBack, toNext, thisLog} from '@/components/app/js/globals'
    import {mdiClockTimeFour, mdiClockTimeFourOutline, mdiMarker, mdiMarkerCancel} from '@mdi/js';


    export default {
        name: 'Ttime',
        data: () => ({
            name: 'Ttime',
            header: 'Выберите время',
            selectedItem: null,
            loading: true,
            icon: {
                yes: mdiClockTimeFour,
                no: mdiClockTimeFourOutline
            } ,
            log: true,
            alertError: {
                show: false,
                text: null
            },
            alertWarning: {
                show: false,
                text: null
            },

            arr: [
                /*
                {
                    FREE : '1',
                    NNAME: '08:00-08:10',
                    REM: 'Свободно',
                    DDATE : '03.11.2020',
                    TB: '08:00',
                    TE: '08:10'
                }
                */
            ]
        }),
        computed: {
            LPU() {
                return this.$store.getters.LPU;
            },
            FIN() {
                return this.$store.getters.FIN;
            },
            AGE() {
                return this.$store.getters.AGE;
            },
            MEDG() {
                return this.$store.getters.MEDG;
            },
            MED() {
                return this.$store.getters.MED;
            },
            DDATE() {
                return this.$store.getters.DDATE;
            },
            TTIME() {
                return this.$store.getters.TTIME;
            },
            is_actual() {
                return (this.$store.getters.LPU.actual && this.$store.getters.LPU.actual==='1') &&
                       (this.$store.getters.FIN.actual && this.$store.getters.FIN.actual==='1') &&
                       (this.$store.getters.AGE.actual && this.$store.getters.AGE.actual==='1') &&
                       (this.$store.getters.MEDG.actual && this.$store.getters.MEDG.actual==='1') &&
                       (this.$store.getters.MED.actual && this.$store.getters.MED.actual==='1') &&
                       (this.$store.getters.DDATE.actual && this.$store.getters.DDATE.actual==='1')
            },
            navBackShow() {
                //console.log('-----',this.alertError.show, this.alertWarning.show)
                return (this.alertError.show || this.alertWarning.show)
            }

        },
        async beforeMount() {
            try {
                if (this.is_actual) {

                    const url = this.$store.getters.LPU.resturl + '/gettime'
                    thisLog(this, ['url', url])
                    const data = {
                        free: '',
                        nppkard: '',
                        nppmed: this.MED.NPP,
                        nppspec: '',
                        ddate: this.DDATE.DDATE,
                        opt: this.FIN.opt,
                        tb: '',
                        te: ''
                    }
                    try {
                        let res = await post(url, data)
                        this.arr = res.data
                        thisLog(this, [` TTIME >>>>>>>>> `, this.arr])
                        if (this.arr.length == 0) {
                            const _DDATE=this.$store.getters.DDATE
                            this.alertWarning.text = `Не найдено данных на дату "${_DDATE.DDATE}"`
                            this.alertWarning.show = true
                            //pushBack(this,{nodata: true, prev: true})
                        }
                    } catch (err) {
                        this.alertError.text = err
                        this.alertError.show = true
                        //pushErr(this, err)
                    }
                } else {
                    this.alertWarning.text = "Не найдено данных - повторите попытку еще раз"
                    this.alertWarning.show = true
                    this.navBack()  //каскадный откат
                    //pushBack(this,{nodata: true})
                }
                const _TTIME=this.$store.getters.TTIME
                this.selectedItem = (_TTIME && typeof _TTIME.selectedItem === 'number' ? _TTIME.selectedItem : null)

                //подтвердить актуальность selectedItem при переходах!!!
                if( !(typeof this.selectedItem == 'number' && this.selectedItem+1 <= this.arr.length  && this.arr[this.selectedItem].FREE==='1') ){
                    //console.log('## bad')
                    this.selectedItem = null
                    this.$store.dispatch('SET_TTIME_ACTUAL', '0')
                }

                //console.log('loading false')
            }catch(e){
                alert(e)
                postLog(this.$store.getters.LPU.resturl, { alert: e, place: 'Ttime#1' } )
            }
            this.loading=false
        },
        methods: {
            onNext(i){
                try {
                    this.$store.dispatch('SET_TTIME', {selectedItem: i, ...this.arr[i]})
                    toNext(this)
                }catch(err){
                    this.alertError.text = err
                    this.alertError.show = true
                    postLog(this.$store.getters.LPU.resturl, { err: err, place: 'Ttime#2' } )
                }

            },
            async navBack(){
                this.drawer=false
                //console.log('navback',this)
                toBack(this,{prev:true, noerr:true, nomsg:true})
            }
        }



    }
</script>
